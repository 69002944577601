<template>
  <v-dialog v-model="dialog" width="800">
    <v-card class="pb-2" v-if="section">
      <v-card-title class="text-h5 grey lighten-2">
        {{ isNew ? "新增場次" : "修改場次" }}
      </v-card-title>
      <v-card-text class="mt-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <div class="text-h6 font-weight-bold">基本資訊</div>
          <v-row>
            <v-col cols="12">
              <v-checkbox
                v-model="section.enable"
                label="開放預約"
                hide-details
              ></v-checkbox>
            </v-col>

            <v-col cols="6" sm="3">
              <v-combobox
                :items="所有行政區"
                v-model="section.行政區"
                outlined
                label="*行政區"
                dense
                hide-details
                :rules="[(v) => !!v || '此為必填欄位']"
                :disabled="!!this.$store.state.user.行政區"
              ></v-combobox>
            </v-col>

            <v-col cols="6" sm="3">
              <v-menu
                :close-on-content-click="true"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="section.日期"
                    :rules="[(v) => !!v || '此為必填欄位']"
                    label="*日期"
                    outlined
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="section.日期"
                  no-title
                  scrollable
                  :show-current="false"
                  :min="expiredDate"
                  locale="zh-tw"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field
                label="人數上限"
                v-model.number="section.人數上限"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3">
              <v-select
                label="接種疫苗"
                v-model="section.接種疫苗"
                :items="vaccineType"
                outlined
                dense
                hide-details
                multiple
              >
              </v-select>
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field
                label="*開始時間"
                v-model="section.開始時間"
                :rules="[
                  (v) => !!v || '',
                  (v) =>
                    /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/.test(v) ||
                    '時間格式錯誤',
                ]"
                outlined
                dense
                hide-details
                placeholder="08:00"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field
                label="*結束時間"
                v-model="section.結束時間"
                :rules="[
                  (v) => !!v || '',
                  (v) =>
                    /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/.test(v) ||
                    '時間格式錯誤',
                ]"
                outlined
                dense
                hide-details
                placeholder="17:30"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" sm="6">
              <v-combobox
                :items="mapLocations"
                label="*地點"
                :rules="[(v) => !!v || '此為必填欄位']"
                v-model="section.地點"
                outlined
                dense
                hide-details
                @change="setLocation"
              ></v-combobox>
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field
                label="醫事機構代碼"
                v-model="section.醫事機構代碼"
                outlined
                dense
                hide-details
              >
              </v-text-field>
            </v-col>

            <v-col cols="6" sm="3">
              <v-text-field
                label="電話"
                v-model="section.電話"
                outlined
                dense
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6"
              ><v-text-field
                label="地址"
                v-model="section.地址"
                outlined
                dense
                hide-details
              >
              </v-text-field
            ></v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = false"> 關閉 </v-btn>
        <v-btn color="primary" @click="update" v-if="isNew"> 確定新增 </v-btn>
        <v-btn color="primary" @click="update" v-else> 儲存修改 </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { 行政區, vaccineTypes } from "@/libs/const";
import util from "@/mixins/util";
import dayjs from "dayjs";
export default {
  name: "SectionDialog",
  props: ["value", "theSection"],
  mixins: [util],
  data: () => ({
    valid: false,
    section: null,
    datePicker: false,
    locations: [],
  }),
  computed: {
    mapLocations() {
      return this.locations.map((o) => o.場次地點);
    },
    vaccineType() {
      // let types = _.groupBy(vaccineTypes, "type");
      // let options = [];
      // for (let type in types) {
      //   options.push({ header: type });
      //   options.push(...types[type].map((o) => o.key));
      // }

      // return options;
      return vaccineTypes.map((o) => o.key);
    },
    expiredDate() {
      return dayjs().format("YYYY-MM-DD");
    },
    所有行政區() {
      let place = _.cloneDeep(行政區);
      return place;
    },
    isNew() {
      return !this.section?._id;
    },
    dialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  async created() {
    await this.getLocations();
    this.section = { ...this.theSection };
    if (this.isNew) {
      if (this.$store.state.user.行政區) {
        this.section.行政區 = this.$store.state.user.行政區;
      }
      this.section.地點 = this.$store.state.user.場次地點 || "";
      this.section.電話 = this.$store.state.user.場次電話 || "";
      this.section.地址 = this.$store.state.user.場次地址 || "";
      this.section.醫事機構代碼 = this.$store.state.user.醫事機構代碼 || "";
      this.section.enable = false;
      this.section.接種疫苗 = [];
    }
    if (!Array.isArray(this.section.接種疫苗)) {
      this.section.接種疫苗 = this.section.接種疫苗?.split(",") || [];
    }
  },
  methods: {
    setLocation(location) {
      if (location) {
        let loc = this.locations.find((o) => o.場次地點 == location);
        this.section.電話 = loc.場次電話;
        this.section.地址 = loc.場次地址;
        this.section.醫事機構代碼 = loc.醫事機構代碼;
      }
    },
    async getLocations() {
      let res = await this.axios.get("/dashboard/locations");
      let locations = res.data;
      if (this.$store.state.user.行政區) {
        locations = locations.filter(
          (location) => location.行政區 == this.$store.state.user.行政區
        );
      }

      this.locations = locations;
    },
    async update() {
      this.valid = this.$refs.form.validate();
      if (this.valid) {
        try {
          this.section.接種疫苗 = this.section.接種疫苗.join(",");
          this.section.年度 = dayjs(this.section.日期).year() - 1911;
          await this.axios.post("/dashboard/section", this.section);
          this.dialog = false;
          this.$toast.success(this.section._id ? "修改成功" : "新增成功");
          this.$emit("load");
        } catch (e) {
          this.$toast.error(this.section._id ? "修改失敗" : "新增失敗");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.check-item {
  width: 120px;
  display: inline-block;
}
</style>
