<template>
  <v-container>
    <h3 class="page-title">報到作業</h3>
    <v-row>
      <v-col cols="6" sm="2">
        <v-autocomplete
          v-model="selected.行政區"
          outlined
          :items="行政區"
          label="行政區"
          dense
          hide-details
          :disabled="!permissions.canChangeDistrict"
        ></v-autocomplete>
      </v-col>
      <v-col cols="6" sm="2">
        <v-text-field
          v-model.number="selected.年度"
          outlined
          label="年度"
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="6" sm="2" v-if="isAdmin">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="date"
              label="日期"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
              dense
              hide-details
              clearable
              @click:clear="date = null"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            no-title
            @input="checkClose"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="6" sm="2">
        <v-autocomplete
          v-model="selected.場次"
          :items="uniqSections"
          label="場次"
          no-data-text="尚無場次"
          outlined
          dense
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-col cols="6" sm="2">
        <v-select
          v-model="selected.篩選條件"
          :items="['全部', '已報到', '未報到']"
          label="篩選條件"
          outlined
          dense
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field
          v-model="selected.關鍵字"
          label="關鍵字"
          outlined
          dense
          hide-details
        >
          <template v-slot:append>
            <v-icon class="mr-1" @click="selected.關鍵字 = null">
              mdi-close
            </v-icon>
            <v-icon class="mr-1" @click="loadList"> mdi-magnify </v-icon>
            <v-icon @click="scanHandler(selected.關鍵字)">
              mdi-barcode-scan
            </v-icon>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="5">
        <v-btn class="mr-2" color="error" @click="openDialog"> 現場掛號 </v-btn>
        <v-menu offset-y open-on-hover>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mr-2" color="warning" v-bind="attrs" v-on="on">
              匯出名單
            </v-btn>
          </template>
          <v-list>
            <v-list-item link @click="exportList">
              <v-list-item-title>預約名單</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="exportNIIS">
              <v-list-item-title>NIIS</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn color="primary" @click="loadList">重整名單</v-btn>

        <v-btn ref="btnPrint" v-print="printObj" color="primary" class="d-none">
          第一階段民眾資料列印
        </v-btn>
      </v-col>

      <v-col
        cols="12"
        sm="3"
        class="ml-auto text-right mt-1 text-body-1 font-weight-bold"
      >
        <div class="d-inline mr-4">預約：{{ list.length }}</div>
        <div class="d-inline mr-4">
          報到：{{ list.filter((v) => v.已報到).length }}
        </div>
      </v-col>
    </v-row>

    <div class="mt-4">
      <v-data-table
        :headers="headers"
        :items="filterList"
        no-data-text="尚無預約"
        :mobile-breakpoint="0"
        :footer-props="{
          itemsPerPageText: '每頁幾筆',
          itemsPerPageOptions: [10, 30, -1],
        }"
      >
        <template v-slot:item.序號="{ index }"> {{ index + 1 }} </template>
        <template v-slot:item.退掛="{ item }">
          <v-icon color="" @click="cancel(item)"> mdi-close </v-icon>
        </template>
        <template v-slot:item.篩檢項目="{ item }">
          {{ Object.keys(lodash.pickBy(item.篩檢項目, (o) => o)).join("、") }}
          <v-icon
            class="ml-2 pointer"
            small
            @click="openReturnDialog(item.身分證字號)"
            >mdi-pencil</v-icon
          >
        </template>

        <template v-slot:item.列印="{ item }">
          <v-icon
            class="pointer"
            @click="singlePrint(item)"
            v-if="item.報到號碼"
          >
            mdi-printer
          </v-icon>
        </template>
        <template v-slot:item.報到號碼="{ item }">
          {{ item.報到號碼 || "-" }}
        </template>

        <template v-slot:item.已報到="{ item }">
          <v-simple-checkbox
            @input="toggleCheckin(item)"
            :off-icon="
              item.已報到 === null
                ? 'mdi-crop-free'
                : 'mdi-checkbox-blank-outline'
            "
            v-model="item.已報到"
            :ripple="false"
          ></v-simple-checkbox>
        </template>
      </v-data-table>
    </div>
    <section id="printBlock" v-if="printReady">
      <Page :person="checkinPerson" :section="theSection"></Page>
    </section>
    <WalkinDialog
      v-if="dialog"
      v-model="dialog"
      :keyword="selected.關鍵字"
      :section="theSection"
      @loadAndPrint="loadAndPrint"
    ></WalkinDialog>
  </v-container>
</template>

<script>
import { getYear, vaccineTypes } from "@/libs/const";
import barcodeReader from "@wrurik/barcode-scanner";
import downloadCsv from "download-csv";
import { 行政區, userGroup } from "@/libs/const";
import dayjs from "dayjs";
import _ from "lodash";
import util from "@/mixins/util";
import Page from "@/components/Print/page";
import print from "vue-print-nb";
import WalkinDialog from "@/components/Checkin/walkinDialog";

const 年度 = getYear();
const all行政區 = {
  text: "全部",
  value: null,
};

export default {
  name: "Checkin",
  directives: {
    print,
  },
  components: { Page, WalkinDialog },
  mixins: [util],
  data: () => ({
    sections: [],
    list: [],
    printReady: false,
    checkinPerson: null,
    dialog: false,
    selected: {
      行政區: null,
      年度,
      場次: null,
      篩選條件: "全部",
      關鍵字: "",
    },

    lodash: _,
    date: null,
    menu: false,

    printObj: {
      id: "#printBlock",
      extraCss: `https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css`,
    },
  }),
  computed: {
    theSection() {
      return _.find(this.sections, this.selected.場次);
    },
    行政區() {
      let place = _.cloneDeep(行政區);
      place.unshift(all行政區);
      return place;
    },
    headers() {
      return [
        { text: "序號", value: "序號" },

        { text: "姓名", value: "姓名" },
        { text: "身分證字號", value: "身分證字號" },
        { text: "出生年月日", value: "出生年月日" },
        { text: "電話", value: "電話" },
        { text: "接種疫苗", value: "接種疫苗" },
        { text: "已報到", value: "已報到" },
        { text: "報到時間", value: "報到時間", width: "170px" },
        { text: "報到號碼", value: "報到號碼" },
        { text: "退掛", value: "退掛" },
      ];
    },
    filterList() {
      let list = [...this.list];
      if (this.selected.關鍵字) {
        list = list.filter((person) => {
          return (
            person.姓名?.includes(this.selected.關鍵字) ||
            person.身分證字號?.includes(this.selected.關鍵字) ||
            person.出生年月日?.toString().includes(this.selected.關鍵字)
          );
        });
      }
      switch (this.selected.篩選條件) {
        case "已報到":
          list = list.filter((person) => person.已報到);
          break;
        case "未報到":
          list = list.filter((person) => !person.已報到);
          break;
      }
      return list;
    },

    uniqSections() {
      let sections = this.sections.map((row) => ({
        text: `${dayjs(row.日期).format("MM/DD")} ${row.地點}`,
        value: {
          日期: row.日期,
          地點: row.地點,
        },
      }));
      sections = _.uniqWith(sections, _.isEqual);
      sections = _.sortBy(sections, ["text"]);

      return sections;
    },
  },
  async created() {
    if (this.$store.state.user.行政區) {
      this.selected.行政區 = this.$store.state.user.行政區;
    }
    await this.loadSection();
    this.cancelScan = barcodeReader.onScan(this.scanHandler, {
      scanDuration: 500,
    });
  },
  beforeDestroy() {
    this.cancelScan();
  },

  methods: {
    openDialog() {
      if (!this.selected.場次) {
        return false;
      }
      this.dialog = true;
    },
    async loadAndPrint(id) {
      await this.loadList();
      let person = this.list.find((o) => o.身分證字號 == id);
      this.print(person);
    },
    print(person) {
      this.checkinPerson = person;
      this.printReady = true;
      this.$refs.btnPrint.$el.click();
      this.$nextTick(() => {
        this.printReady = false;
      });
    },
    checkClose() {
      if (this.date) {
        this.menu = false;
      }
    },

    theAge(date) {
      date = parseInt(date) + 19110000 + "";
      return dayjs().diff(date, "year");
    },
    async cancel(person) {
      if (confirm(`確定退掛 ${person.身分證字號} (${person.姓名})？`)) {
        try {
          await this.axios.post(
            `/dashboard/section/cancel/${this.theSection._id}/${person.身分證字號}`
          );
          this.$toast.success("退掛成功");
          await this.loadList();
        } catch (error) {
          this.$toast.error(error.response.data);
        }
      }
    },
    exportList() {
      if (!this.selected.場次) {
        return false;
      }
      let data = this.list.map((row) => {
        let {
          日期,
          地點,
          建議時間,
          報到號碼,
          姓名,
          身分證字號,
          出生年月日,
          電話,
          接種疫苗,
          已報到,
          報到時間,
        } = row;
        return {
          日期,
          地點,
          建議時間,
          報到號碼,
          姓名,
          身分證字號,
          出生年月日,
          電話,
          接種疫苗: 接種疫苗.replace(/,/g, "、").replace(/-S/g, ""),
          已報到: 已報到 ? "是" : "否",
          報到時間,
        };
      });
      let fileName = `${this.selected.場次?.日期} ${this.selected.場次?.地點}預約名單`;
      downloadCsv(data, {}, fileName);
    },

    exportNIIS() {
      if (!this.selected.場次) {
        return false;
      }
      let fileName = `${this.selected.場次?.日期} ${this.selected.場次?.地點} NIIS 匯入檔.csv`;

      let list = this.list
        .filter((row) => row.已報到)
        .map((row) => ({
          身分證號: row.身分證字號,
          姓名: row.姓名,
          性別: this.性別(row) == "男" ? "M" : "F",
          出生日期: row.出生年月日,
          同胎次序: 1,
          通訊地址: "",
          電話: row.電話,
          父或母身分證號: "",
          接種機構: "",
          接種日期: this.toChineseDate(this.selected.場次?.日期),
          疫苗種類: this.toVaccineCode(row.接種疫苗),
          疫苗劑別: 1,
          疫苗批號: "",
          疫苗廠商: "",
          疫苗型別: "",
          曾經接種流感疫苗: "",
          身份別: "",
        }));

      list = _.flatMap(list, (o) => {
        let vaccines = o.疫苗種類.split(",");
        return vaccines.map((v) => {
          let clone = _.cloneDeep(o);
          clone.疫苗種類 = v;
          return clone;
        });
      });

      downloadCsv(list, {}, fileName);
    },

    scanHandler(value, e) {
      if (!value) return;
      if (
        // document.activeElement.id != "keyword" &&
        /^[A-Z](1|2)\d{8}$/i.test(value)
      ) {
        this.selected.關鍵字 = value;
        let finded = this.list.find((o) => o.身分證字號 == value);

        if (finded) {
          if (!finded.已報到) {
            finded.已報到 = true;
            this.toggleCheckin(finded);
            this.$toast.success(
              `${finded.身分證字號}(${finded.姓名 || ""}) 報到完成`
            );
          } else {
            this.$toast.error(
              `${finded.身分證字號}(${finded.姓名 || ""}) 已報到完畢`
            );
          }
        } else {
          this.$toast.error(`本場次找不到此民眾，若要掛號可使用現場掛號！`);
        }
      }
    },
    toChineseDate(date) {
      let year = parseInt(date.slice(0, 4)) - 1911;
      return `${year}${date.slice(4)}`.replace(/-/g, "");
    },
    toVaccineCode(vaccine) {
      let matchVaccine = vaccineTypes.find((o) => o.key == vaccine);
      return matchVaccine?.code || matchVaccine?.key || vaccine;
    },
    async loadSection() {
      let params = {
        年度: this.selected.年度,
        行政區: this.selected.行政區,
        日期: this.date,
      };
      this.sections = (
        await this.axios.get("/dashboard/section", {
          params,
        })
      ).data;

      this.selected.場次 = null;
      this.list = [];
    },
    async loadList() {
      if (!this.selected.場次) {
        return false;
      }
      let params = {
        日期: this.selected.場次?.日期,
        地點: this.selected.場次?.地點,
      };

      let list = (
        await this.axios.get(`/dashboard/section/reserve`, {
          params,
        })
      ).data;
      this.list = _.sortBy(list, ["報到號碼"]);
    },
    async toggleCheckin(person) {
      let result;
      try {
        result = (await this.axios.post("/dashboard/section/checkin", person))
          .data;
        if (person.已報到) {
          this.print(person);
        }
      } catch (e) {
        console.log(e);
        if (person.已報到) {
          this.$toast.error(
            "請注意！目前網路連線失敗，報到動作沒有傳送至伺服器！"
          );
        }
      }

      if (result.報到時間) {
        person.報到時間 = result.報到時間;
        person.報到號碼 = result.報到號碼;
      }
    },
  },
  watch: {
    "selected.年度"() {
      this.loadSection();
    },
    "selected.行政區"() {
      this.loadSection();
    },
    "selected.場次"() {
      this.loadList();
    },
    date() {
      this.loadSection();
    },
  },
};
</script>

<style lang="scss" scoped>
#printBlock {
  position: relative;
  margin: 0;
  padding: 0;
  // color: red;
}
</style>
