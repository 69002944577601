<template>
  <div class="printPage">
    <div style="position: fixed; top: 0px; left: 0px; font-size: 18px;">
      #{{person?.報到號碼 }}
      <br>
      <span style="font-size: 12px;">
        {{ person?.報到時間 }}
      </span>
    </div>
    <p style="text-align: center; font-size: 18pt; margin-bottom: 20px">
      <span style="font-family: 標楷體; font-weight: bold; -aw-import: spaces"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: 標楷體; font-weight: bold">【</span
      ><span style="font-family: 標楷體; font-weight: bold; color: #ff0000"
        >流感</span
      ><span style="font-family: 標楷體; font-weight: bold"
        >、肺炎鏈球菌、新冠疫苗接種同意書】</span
      ><span style="font-family: 標楷體"> </span
      ><span style="font-family: 標楷體; font-size: 10pt; -aw-import: spaces"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: 標楷體; font-size: 10pt"
        >{{ year }}.{{ month }}.{{ day }}</span
      >
    </p>
    <table
      cellspacing="0"
      cellpadding="0"
      class="TableGrid"
      style="
        width: 567.5pt;
        border: 0.75pt solid #000000;
        -aw-border: 0.5pt single;
        -aw-border-insideh: 0.5pt single #000000;
        -aw-border-insidev: 0.5pt single #000000;
        border-collapse: collapse;
      "
    >
      <tr>
        <td
          style="
            width: 201.6pt;
            border-right-style: solid;
            border-right-width: 0.75pt;
            border-bottom-style: solid;
            border-bottom-width: 0.75pt;
            padding-right: 5.03pt;
            padding-left: 5.03pt;
            vertical-align: middle;
            -aw-border-bottom: 0.5pt single;
            -aw-border-right: 0.5pt single;
          "
        >
          <p style="text-align: center; font-size: 20pt; margin: 4px">
            <span style="font-family: 標楷體; font-weight: bold; color: #ff0000"
              >□流感疫苗</span
            >
          </p>
        </td>
        <td
          style="
            width: 166.4pt;
            border-right-style: solid;
            border-right-width: 0.75pt;
            border-left-style: solid;
            border-left-width: 0.75pt;
            border-bottom-style: solid;
            border-bottom-width: 0.75pt;
            padding-right: 5.03pt;
            padding-left: 5.03pt;
            vertical-align: middle;
            -aw-border-bottom: 0.5pt single;
            -aw-border-left: 0.5pt single;
            -aw-border-right: 0.5pt single;
          "
        >
          <p style="text-align: center; font-size: 20pt">
            <span style="font-family: 標楷體; font-weight: bold"
              >□肺炎鏈球菌疫苗</span
            >
          </p>
        </td>
        <td
          style="
            width: 166.35pt;
            border-left-style: solid;
            border-left-width: 0.75pt;
            border-bottom-style: solid;
            border-bottom-width: 0.75pt;
            padding-right: 5.03pt;
            padding-left: 5.03pt;
            vertical-align: middle;
            -aw-border-bottom: 0.5pt single;
            -aw-border-left: 0.5pt single;
          "
        >
          <p style="text-align: center; font-size: 20pt; margin: 4px">
            <span style="font-family: 標楷體; font-weight: bold"
              >□新冠疫苗</span
            >
          </p>
        </td>
      </tr>
      <tr style="height: 288.1pt">
        <td
          class="section-1"
          style="
            width: 201.6pt;
            border-top-style: solid;
            border-top-width: 0.75pt;
            border-right-style: solid;
            border-right-width: 0.75pt;
            padding-right: 5.03pt;
            padding-left: 5.03pt;
            vertical-align: top;
            -aw-border-right: 0.5pt single;
            -aw-border-top: 0.5pt single;
          "
        >
          <p style="text-align: justify; line-height: 15pt">
            <span style="font-family: 標楷體; font-size: 13pt"
              >□65歲以上長者</span
            >
          </p>
          <p style="text-align: justify; line-height: 15pt">
            <span style="font-family: 標楷體; font-size: 13pt"
              >□50 - 64歲民眾</span
            >
          </p>
          <p
            style="
              margin-left: 22.8pt;
              text-indent: -22.8pt;
              text-align: justify;
              line-height: 15pt;
            "
          >
            <span style="font-family: 標楷體; font-size: 13pt"
              >□高風險慢性病患具糖尿病、肝、心、肺、血管、腎臟、HIV等疾病、BMI≧30</span
            >
          </p>
          <p style="text-align: justify; line-height: 15pt">
            <span style="font-family: 標楷體; font-size: 13pt"
              >□罕見疾病患者</span
            >
          </p>
          <p style="text-align: justify; line-height: 15pt">
            <span style="font-family: 標楷體; font-size: 13pt"
              >□重大傷病者</span
            >
          </p>
          <p style="text-align: justify; line-height: 15pt">
            <span style="font-family: 標楷體; font-size: 13pt">□孕婦</span>
          </p>
          <p
            style="
              margin-left: 22.8pt;
              text-indent: -22.8pt;
              text-align: justify;
              line-height: 15pt;
            "
          >
            <span style="font-family: 標楷體; font-size: 13pt"
              >□六個月內嬰兒之父母-</span
            >
          </p>
          <p style="text-indent: 28pt; text-align: justify; line-height: 15pt">
            <span style="font-family: 標楷體; font-size: 13pt"
              >新生兒出生日期________</span
            >
          </p>
          <p
            style="
              margin-left: 22.8pt;
              text-indent: -22.8pt;
              text-align: justify;
              line-height: 15pt;
            "
          >
            <span style="font-family: 標楷體; font-size: 13pt">□其他 </span>
          </p>
          <p
            style="
              margin-left: 22.8pt;

              text-align: justify;
              line-height: 15pt;
            "
          >
            <span style="font-family: 標楷體; font-size: 13pt"
              >幼兒園托育人員、托育機構專業人員、保母、醫護、防疫、禽畜業</span
            >
          </p>
          <p style="text-align: justify; line-height: 14pt">
            <span style="font-family: 標楷體; font-size: 10pt"
              >(單位名稱及職稱)</span
            >
          </p>
          <div class="section-border" style="margin-bottom: 4px">
            <p style="text-align: justify; font-size: 10ptl">
              <span style="font-family: 標楷體">□不可接種，已接種過</span
              ><span style="font-family: 標楷體; font-weight: bold"
                ><br>(接種日期</span
              ><span style="font-family: 標楷體">__________)</span>
            </p>
            <p style="text-align: justify; font-size: 10pt">
              <span style="font-family: 標楷體">(掛號人員蓋章)</span>
            </p>
            <p style="text-align: justify; font-size: 10pt">
              <span style="font-family: 標楷體">疫苗廠牌：</span>
            </p>
          </div>
          <p style="text-align: justify; font-size: 10pt; margin-bottom: 4px">
            <span style="font-family: 標楷體">疫苗批號：</span>
          </p>
        </td>
        <td
          class="section-1"
          style="
            width: 166.4pt;
            border-top-style: solid;
            border-top-width: 0.75pt;
            border-right-style: solid;
            border-right-width: 0.75pt;
            border-left-style: solid;
            border-left-width: 0.75pt;
            padding-right: 5.03pt;
            padding-left: 5.03pt;
            vertical-align: top;
            -aw-border-left: 0.5pt single;
            -aw-border-right: 0.5pt single;
            -aw-border-top: 0.5pt single;
          "
        >
          <p style="font-size: 14pt">
            <span style="font-family: 標楷體">□ PCV13</span
            ><a name="_heading_h.gjdgxs"></a
            ><span style="font-family: 標楷體; -aw-import: spaces"
              >&#xa0;&#xa0;&#xa0;&#xa0; </span
            ><span style="font-family: 標楷體; color: #d9d9d9">□ PCV15</span>
          </p>
          <p style="font-size: 14pt">
            <span style="font-family: 標楷體">□ PPV23</span>
          </p>
          <p style="font-size: 12pt">
            <span style="font-family: 標楷體; -aw-import: ignore">&#xa0;</span>
          </p>
          <div class="section-border" style="padding-bottom: 4px">
            <p style="font-size: 10ptl; margin-top: 20px">
              <span style="font-family: 標楷體">□均不可接種，已接種過</span>
            </p>
            <p style="font-size: 10ptl">
              <span style="font-family: 標楷體; -aw-import: spaces"
                >&#xa0;&#xa0; </span
              ><span style="font-family: 標楷體">PCV13/PPV23</span>
            </p>
            <p style="font-size: 10ptl">
              <span style="font-family: 標楷體">□已查詢系統</span>
            </p>
            <p style="font-size: 10pt">
              <span style="font-family: 標楷體">PCV13 </span
              ><span style="font-family: 標楷體; font-weight: bold"
                >接種日期 </span
              ><span style="font-family: 標楷體">___________</span>
            </p>
            <p style="font-size: 10pt">
              <span style="font-family: 標楷體">PPV23 </span
              ><span style="font-family: 標楷體; font-weight: bold"
                >接種日期 </span
              ><span style="font-family: 標楷體">___________</span>
            </p>
            <p style="font-size: 10pt">
              <span style="font-family: 標楷體">(掛號人員蓋章)</span>
            </p>
          </div>

          <p style="font-size: 10pt">
            <span style="font-family: 標楷體; -aw-import: ignore">&#xa0;</span>
          </p>
          <p style="font-size: 10pt">
            <span style="font-family: 標楷體">疫苗廠牌：</span>
          </p>
          <p style="font-size: 10pt">
            <span style="font-family: 標楷體">疫苗批號：</span>
          </p>
        </td>
        <td
          class="section-1"
          style="
            width: 166.35pt;
            border-top-style: solid;
            border-top-width: 0.75pt;
            border-left-style: solid;
            border-left-width: 0.75pt;
            padding-right: 5.03pt;
            padding-left: 5.03pt;
            vertical-align: top;
            -aw-border-left: 0.5pt single;
            -aw-border-top: 0.5pt single;
          "
        >
          <p style="font-size: 14pt">
            <span style="font-family: 標楷體">□</span>
            <span
              style="font-family: 標楷體; font-size: 13pt; -aw-import: spaces"
              >&#xa0;</span
            ><span style="font-family: 標楷體">JN.1</span
            ><span style="font-family: 標楷體; -aw-import: spaces"
              >&#xa0;
            </span>
          </p>
          <p style="font-size: 14pt">
            <span style="font-family: 標楷體">□</span
            ><span
              style="
                font-family: 標楷體;
                font-size: 10pt;
                text-decoration: underline;
                -aw-import: spaces;
              "
              >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
            </span>
          </p>
          <p style="font-size: 14pt; margin-top: 20px">
            <span style="font-family: 標楷體">是否已懷孕</span
            ><span style="font-family: 標楷體; -aw-import: spaces">&#xa0; </span
            ><span style="font-family: 標楷體">是□</span
            ><span style="font-family: 標楷體; -aw-import: spaces"
              >&#xa0;&#xa0; </span
            ><span style="font-family: 標楷體">否□</span>
          </p>
          <p style="font-size: 10pt">
            <span style="font-family: 標楷體; -aw-import: ignore">&#xa0;</span>
          </p>
          <div class="section-border" style="padding-bottom: 4px">
            <p style="font-size: 10ptl; margin-top: 20px">
              <span style="font-family: 標楷體"
                >□不可接種，已接種過_____________</span
              >
            </p>
            <p style="font-size: 10pt">
              <span style="font-family: 標楷體; -aw-import: ignore"
                >&#xa0;</span
              >
            </p>
            <p style="font-size: 10ptl">
              <span style="font-family: 標楷體">□已查詢系統</span>
            </p>
            <p style="font-size: 11pt">
              <span style="font-family: 標楷體; font-weight: bold"
                >疫苗種類 </span
              ><span
                style="
                  font-family: 標楷體;
                  text-decoration: underline;
                  -aw-import: spaces;
                "
                >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
              </span>
            </p>
            <p style="font-size: 10pt">
              <span style="font-family: 標楷體; font-weight: bold"
                >上次接種日期 </span
              ><span style="font-family: 標楷體">_____________</span>
            </p>
            <p style="font-size: 10pt">
              <span style="font-family: 標楷體">(掛號人員蓋章)</span>
            </p>
          </div>

          <p style="font-size: 10pt">
            <span style="font-family: 標楷體; -aw-import: ignore">&#xa0;</span>
          </p>
          <p style="font-size: 10pt">
            <span style="font-family: 標楷體">疫苗廠牌：</span>
          </p>
          <p style="font-size: 10pt">
            <span style="font-family: 標楷體">疫苗批號：</span>
          </p>
        </td>
      </tr>
    </table>
    <p style="line-height: 24pt">
      <span
        style="font-family: 標楷體; font-size: 14pt; background-color: #d8d8d8"
        >【</span
      ><span
        style="
          font-family: 標楷體;
          font-size: 14pt;
          font-weight: bold;
          background-color: #d8d8d8;
        "
        >基本資料</span
      ><span
        style="font-family: 標楷體; font-size: 14pt; background-color: #d8d8d8"
        >】</span
      >
    </p>
    <table
      cellspacing="0"
      cellpadding="0"
      class="TableGrid"
      style="
        border: 0.75pt solid #000000;
        -aw-border: 0.5pt single;
        -aw-border-insideh: 0.5pt single #000000;
        -aw-border-insidev: 0.5pt single #000000;
        border-collapse: collapse;
      "
    >
      <tr style="height: 32.55pt">
        <td
          style="
            width: 284.5pt;
            border-right-style: solid;
            border-right-width: 0.75pt;
            border-bottom-style: solid;
            border-bottom-width: 0.75pt;
            padding-right: 5.03pt;
            padding-left: 5.03pt;
            -aw-border-bottom: 0.5pt single;
            -aw-border-right: 0.5pt single;
          "
        >
          <p style="line-height: 150%; font-size: 14pt">
            <span style="font-family: 標楷體">1.姓名：{{ person.姓名 }}</span>
          </p>
        </td>
        <td
          style="
            width: 284.5pt;
            border-left-style: solid;
            border-left-width: 0.75pt;
            border-bottom-style: solid;
            border-bottom-width: 0.75pt;
            padding-right: 5.03pt;
            padding-left: 5.03pt;

            -aw-border-bottom: 0.5pt single;
            -aw-border-left: 0.5pt single;
          "
        >
          <p style="line-height: 150%; font-size: 14pt">
            <span style="font-family: 標楷體">2.生日：</span
            ><span style="font-family: 標楷體; -aw-import: spaces"
              >&#xa0;&#xa0;&#xa0;&#xa0; </span
            ><span style="font-family: 標楷體">{{birth.year}}年</span
            ><span style="font-family: 標楷體; -aw-import: spaces"
              >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
            ><span style="font-family: 標楷體">{{birth.month}}月</span
            ><span style="font-family: 標楷體; -aw-import: spaces"
              >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
            ><span style="font-family: 標楷體">{{birth.day}}日</span>
          </p>
        </td>
      </tr>
      <tr style="height: 30.9pt">
        <td
          style="
            width: 284.5pt;
            border-top-style: solid;
            border-top-width: 0.75pt;
            border-right-style: solid;
            border-right-width: 0.75pt;
            border-bottom-style: solid;
            border-bottom-width: 0.75pt;
            padding-right: 5.03pt;
            padding-left: 5.03pt;

            -aw-border-bottom: 0.5pt single;
            -aw-border-right: 0.5pt single;
            -aw-border-top: 0.5pt single;
          "
        >
          <p style="line-height: 150%; font-size: 14pt">
            <span style="font-family: 標楷體">3.電話：{{ person.電話 }}</span
            ><span style="font-family: 標楷體; color: #e7e6e6; float: right; margin-right: 4px;">手機優先</span>
          </p>
        </td>
        <td
          style="
            width: 284.5pt;
            border-top-style: solid;
            border-top-width: 0.75pt;
            border-left-style: solid;
            border-left-width: 0.75pt;
            border-bottom-style: solid;
            border-bottom-width: 0.75pt;
            padding-right: 5.03pt;
            padding-left: 5.03pt;

            -aw-border-bottom: 0.5pt single;
            -aw-border-left: 0.5pt single;
            -aw-border-top: 0.5pt single;
          "
        >
          <p style="line-height: 150%; font-size: 14pt">
            <span style="font-family: 標楷體">4.身分證字號：{{ person.身分證字號 }}</span>
          </p>
        </td>
      </tr>
      <tr style="height: 36.8pt; -aw-height-rule: exactly">
        <td
          colspan="2"
          style="
            width: 555.6pt;
            border-top-style: solid;
            border-top-width: 0.75pt;
            padding-right: 5.03pt;
            padding-left: 5.03pt;

            -aw-border-top: 0.5pt single;
          "
        >
          <p style="line-height: 150%; font-size: 14pt">
            <span style="font-family: 標楷體">5.地址：{{ person.地址 }}</span>
          </p>
        </td>
      </tr>
    </table>
    <p style="line-height: 24pt">
      <span
        style="font-family: 標楷體; font-size: 14pt; background-color: #d8d8d8"
        >【</span
      ><span
        style="
          font-family: 標楷體;
          font-size: 14pt;
          font-weight: bold;
          background-color: #d8d8d8;
        "
        >接種前自我評估</span
      ><span
        style="font-family: 標楷體; font-size: 14pt; background-color: #d8d8d8"
        >】</span
      ><span style="font-family: 標楷體; font-size: 16ptl; -aw-import: spaces"
        >&#xa0; </span
      ><span style="font-family: 標楷體; font-size: 14pt!important; font-weight: bold">□</span
      ><span style="font-family: 標楷體; font-size: 13pt"
        >我已經確認無下述接種禁忌，決定接種疫苗。</span
      >
    </p>
    <table
      cellspacing="0"
      cellpadding="0"
      class="TableGrid"
      style="
        width: 571.15pt;
        border: 0.75pt solid #000000;
        -aw-border: 0.5pt single;
        -aw-border-insideh: 0.5pt single #000000;
        -aw-border-insidev: 0.5pt single #000000;
        border-collapse: collapse;
      "
    >
      <tr style="height: 8.15pt">
        <td
          rowspan="2"
          style="
            width: 474.3pt;
            border-right-style: solid;
            border-right-width: 0.75pt;
            border-bottom-style: solid;
            border-bottom-width: 0.75pt;
            padding-right: 5.03pt;
            padding-left: 5.03pt;
            -aw-border-bottom: 0.5pt single;
            -aw-border-right: 0.5pt single;
          "
        >
          <p style="line-height: 115%; font-size: 20pt; vertical-align: middle">
            <span style="font-family: 標楷體">評估結果</span>
            <span
              style="
                float: right;
                line-height: 115%;
                font-family: 標楷體;
                font-size: 16pt;
                background-color: #c0c0c0;
                padding-right: 60px;
                padding-left: 4px;
                line-height: 30px;
              "
              >體溫：</span
            ><span
              style="
                line-height: 115%;
                font-family: 標楷體;
                font-size: 16pt;
                -aw-import: spaces;
              "
              >&#xa0;
            </span>
          </p>
        </td>
        <td
          colspan="2"
          style="
            width: 74.5pt;
            border-left-style: solid;
            border-left-width: 0.75pt;
            border-bottom-style: solid;
            border-bottom-width: 0.75pt;
            padding-right: 5.03pt;
            padding-left: 5.03pt;
            vertical-align: middle;
            background-color: #f2f2f2;
            -aw-border-bottom: 0.5pt single;
            -aw-border-left: 0.5pt single;
          "
        >
          <p style="text-align: center; line-height: 14pt">
            <span style="font-family: 標楷體; font-size: 12pt">評估結果</span>
          </p>
        </td>
      </tr>
      <tr style="height: 14.75pt">
        <td
          style="
            width: 32.05pt;
            border-style: solid;
            border-width: 0.75pt;
            padding-right: 5.03pt;
            padding-left: 5.03pt;
            vertical-align: middle;
            background-color: #f2f2f2;
            -aw-border: 0.5pt single;
          "
        >
          <p style="text-align: center; line-height: 14pt">
            <span style="font-family: 標楷體; font-size: 11pt">是</span>
          </p>
        </td>
        <td
          style="
            width: 31.65pt;
            border-top-style: solid;
            border-top-width: 0.75pt;
            border-left-style: solid;
            border-left-width: 0.75pt;
            border-bottom-style: solid;
            border-bottom-width: 0.75pt;
            padding-right: 5.03pt;
            padding-left: 5.03pt;
            vertical-align: middle;
            background-color: #f2f2f2;
            -aw-border-bottom: 0.5pt single;
            -aw-border-left: 0.5pt single;
            -aw-border-top: 0.5pt single;
          "
        >
          <p style="text-align: center; line-height: 14pt">
            <span style="font-family: 標楷體; font-size: 11pt">否</span>
          </p>
        </td>
      </tr>
      <tr style="height: 16.05pt">
        <td
          style="
            width: 474.3pt;
            border-top-style: solid;
            border-top-width: 0.75pt;
            border-right-style: solid;
            border-right-width: 0.75pt;
            border-bottom-style: solid;
            border-bottom-width: 0.75pt;
            padding-right: 5.03pt;
            padding-left: 5.03pt;
            -aw-border-bottom: 0.5pt single;
            -aw-border-right: 0.5pt single;
            -aw-border-top: 0.5pt single;
          "
        >
          <p style="line-height: 24pt">
            <span style="font-family: 標楷體; font-size: 14pt"
              >1.過去注射疫苗或藥物是否有嚴重過敏史。</span
            >
          </p>
        </td>
        <td
          style="
            width: 32.05pt;
            border-style: solid;
            border-width: 0.75pt;
            padding-right: 5.03pt;
            padding-left: 5.03pt;
            -aw-border: 0.5pt single;
          "
        >
          <p style="text-align: center; font-size: 12pt">
            <span style="font-family: 標楷體; -aw-import: ignore">&#xa0;</span>
          </p>
        </td>
        <td
          style="
            width: 31.65pt;
            border-top-style: solid;
            border-top-width: 0.75pt;
            border-left-style: solid;
            border-left-width: 0.75pt;
            border-bottom-style: solid;
            border-bottom-width: 0.75pt;
            padding-right: 5.03pt;
            padding-left: 5.03pt;
            -aw-border-bottom: 0.5pt single;
            -aw-border-left: 0.5pt single;
            -aw-border-top: 0.5pt single;
          "
        >
          <p style="text-align: center; font-size: 12pt">
            <span style="font-family: 標楷體; -aw-import: ignore">&#xa0;</span>
          </p>
        </td>
      </tr>
      <tr style="height: 16.5pt">
        <td
          style="
            width: 474.3pt;
            border-top-style: solid;
            border-top-width: 0.75pt;
            border-right-style: solid;
            border-right-width: 0.75pt;
            border-bottom-style: solid;
            border-bottom-width: 0.75pt;
            padding-right: 5.03pt;
            padding-left: 5.03pt;

            -aw-border-bottom: 0.5pt single;
            -aw-border-right: 0.5pt single;
            -aw-border-top: 0.5pt single;
          "
        >
          <p style="line-height: 24pt">
            <span style="font-family: 標楷體; font-size: 14pt"
              >2.現在身體是否有不適病徵(如發燒38度C、呼吸困難等)。</span
            >
          </p>
        </td>
        <td
          style="
            width: 32.05pt;
            border-style: solid;
            border-width: 0.75pt;
            padding-right: 5.03pt;
            padding-left: 5.03pt;
            vertical-align: top;
            -aw-border: 0.5pt single;
          "
        >
          <p style="text-align: center; font-size: 12pt">
            <span style="font-family: 標楷體; -aw-import: ignore">&#xa0;</span>
          </p>
        </td>
        <td
          style="
            width: 31.65pt;
            border-top-style: solid;
            border-top-width: 0.75pt;
            border-left-style: solid;
            border-left-width: 0.75pt;
            border-bottom-style: solid;
            border-bottom-width: 0.75pt;
            padding-right: 5.03pt;
            padding-left: 5.03pt;
            vertical-align: top;
            -aw-border-bottom: 0.5pt single;
            -aw-border-left: 0.5pt single;
            -aw-border-top: 0.5pt single;
          "
        >
          <p style="text-align: center; font-size: 12pt">
            <span style="font-family: 標楷體; -aw-import: ignore">&#xa0;</span>
          </p>
        </td>
      </tr>
      <tr style="height: 12.75pt">
        <td
          style="
            width: 474.3pt;
            border-top-style: solid;
            border-top-width: 0.75pt;
            border-right-style: solid;
            border-right-width: 0.75pt;
            padding-right: 5.03pt;
            padding-left: 5.03pt;

            -aw-border-right: 0.5pt single;
            -aw-border-top: 0.5pt single;
          "
        >
          <p style="line-height: 24pt">
            <span style="font-family: 標楷體; font-size: 14pt"
              >3.是否為免疫低下者，包括接受免疫抑制劑治療者。</span
            >
          </p>
        </td>
        <td
          style="
            width: 32.05pt;
            border-top-style: solid;
            border-top-width: 0.75pt;
            border-right-style: solid;
            border-right-width: 0.75pt;
            border-left-style: solid;
            border-left-width: 0.75pt;
            padding-right: 5.03pt;
            padding-left: 5.03pt;
            vertical-align: top;
            -aw-border-left: 0.5pt single;
            -aw-border-right: 0.5pt single;
            -aw-border-top: 0.5pt single;
          "
        >
          <p style="text-align: center; font-size: 12pt">
            <span style="font-family: 標楷體; -aw-import: ignore">&#xa0;</span>
          </p>
        </td>
        <td
          style="
            width: 31.65pt;
            border-top-style: solid;
            border-top-width: 0.75pt;
            border-left-style: solid;
            border-left-width: 0.75pt;
            padding-right: 5.03pt;
            padding-left: 5.03pt;
            vertical-align: top;
            -aw-border-left: 0.5pt single;
            -aw-border-top: 0.5pt single;
          "
        >
          <p style="text-align: center; font-size: 12pt">
            <span style="font-family: 標楷體; -aw-import: ignore">&#xa0;</span>
          </p>
        </td>
      </tr>
    </table>
    <p style="line-height: 25pt">
      <span
        style="
          font-family: 標楷體;
          font-size: 14pt;
          font-weight: bold;
          -aw-import: spaces;
        "
        >&#xa0;</span
      ><span
        style="
          font-family: 標楷體;
          font-size: 14pt;
          font-weight: bold;
          color: #ff0000;
        "
        >□同意接種，</span
      ><span
        style="
          font-family: 標楷體;
          font-size: 14pt;
          font-weight: bold;
          color: #ff0000;
          background-color: #d8d8d8;
        "
        >立同意書人簽名 </span
      ><span
        style="
          font-family: 標楷體;
          font-size: 14pt;
          font-weight: bold;
          text-decoration: underline;
          color: #ff0000;
          background-color: #d8d8d8;
          -aw-import: spaces;
        "
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span
        style="
          font-family: 標楷體;
          font-size: 14pt;
          font-weight: bold;
          text-decoration: underline;
          color: #ff0000;
          background-color: #d8d8d8;
          -aw-import: spaces;
        "
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span
        style="
          font-family: 標楷體;
          font-size: 14pt;
          font-weight: bold;
          color: #ff0000;
        "
        >□本人 / □關係人</span
      >
    </p>
    <p style="text-indent: 21pt; line-height: 30pt">
      <span
        style="
          font-family: 標楷體;
          font-size: 14pt;
          font-weight: bold;
          color: #ff0000;
        "
        >關係人之身分證字號：_________________</span
      ><span style="font-family: 標楷體; font-size: 13pt; color: #ff0000"
        >_</span
      ><span
        style="
          font-family: 標楷體;
          font-size: 14pt;
          font-weight: bold;
          color: #ff0000;
        "
        >為被接種人之 </span
      ><span
        style="
          font-family: 標楷體;
          font-size: 14pt;
          font-weight: bold;
          text-decoration: underline;
          color: #ff0000;
          -aw-import: spaces;
        "
        >&#xa0;</span
      ><span
        style="
          font-family: 標楷體;
          font-size: 14pt;
          font-weight: bold;
          text-decoration: underline;
          color: #ff0000;
          -aw-import: spaces;
        "
        >&#xa0;&#xa0; </span
      ><span
        style="
          font-family: 標楷體;
          font-size: 14pt;
          font-weight: bold;
          text-decoration: underline;
          color: #ff0000;
          -aw-import: spaces;
        "
        >&#xa0;&#xa0;&#xa0; </span
      ><span
        style="
          font-family: 標楷體;
          font-size: 14pt;
          font-weight: bold;
          text-decoration: underline;
          color: #ff0000;
          -aw-import: spaces;
        "
        >&#xa0;</span
      >
    </p>
    <p style="margin-top: 3.6pt; font-size: 13pt ;text-align: center">
      <span style="font-family: 標楷體; font-weight: bold; "
        >------------------------------------------------------------------------</span
      
    </p>
    <p style="line-height: 19pt">
      <span style="font-family: 標楷體; font-size: 13pt; font-weight: bold"
        >【醫師診察評估】</span
      >
    </p>
    <p style="margin-top: 3.6pt; line-height: 150%; font-size: 13pt">
      <span style="font-family: 標楷體; font-weight: bold; -aw-import: spaces"
        >&#xa0; </span
      ><span style="font-family: 標楷體; font-weight: bold">□可接種 </span
      ><span style="font-family: 標楷體; font-weight: bold; -aw-import: spaces"
        >&#xa0;</span
      ><span style="font-family: 標楷體; font-weight: bold">
        □不可接種，原因____________
      </span>
    </p>
    <p style="margin-top: 3.6pt; line-height: 150%; font-size: 13pt">
      <span style="font-family: 標楷體; -aw-import: spaces">&#xa0; </span
      ><span style="font-family: 標楷體">◎醫師簽章：___________________</span
      ><span style="font-family: 標楷體; -aw-import: spaces">&#xa0; </span>
    </p>
    <p style="margin-top: 3.6pt; line-height: 150%; font-size: 13pt">
      <span style="font-family: 標楷體; font-weight: bold; -aw-import: spaces"
        >&#xa0; </span
      ><span style="font-family: 標楷體">◎接種護理師：__________________</span
      ><span style="font-family: 標楷體; -aw-import: spaces"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: 標楷體; -aw-import: spaces">&#xa0;</span
      ><span style="font-family: 標楷體; -aw-import: spaces"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: 標楷體; -aw-import: spaces">&#xa0;</span
      ><span style="font-family: 標楷體">接種日期：:____年____月____ 日</span>
    </p>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "Page",
  props: ["person", "section"],
  data: () => ({
    year: dayjs().year() - 1911,
    month: dayjs().format("MM"),
    day: dayjs().format("DD"),
  }),
  computed:{
    birth(){
      return {
        year: parseInt(this.person.出生年月日 / 10000),
        month: parseInt(this.person.出生年月日 / 100) % 100,
        day: this.person.出生年月日 % 100,
      }
    
    },
  },
  methods: {},
  created(){
    console.log(this.person)
  }
};
</script>

<style lang="scss" scoped>
.printPage {
  p {
    margin: 0pt;
  }
  table {
    margin-top: 0pt;
    margin-bottom: 0pt;
  }
  h1 {
    margin-top: 24pt;
    margin-bottom: 4pt;
    page-break-inside: avoid;
    page-break-after: avoid;
    line-height: 116%;
    widows: 0;
    orphans: 0;
    font-family: "Aptos Display";
    font-size: 24pt;
    font-weight: normal;
    color: #2e74b5;
  }
  h2 {
    margin-top: 8pt;
    margin-bottom: 4pt;
    page-break-inside: avoid;
    page-break-after: avoid;
    line-height: 116%;
    widows: 0;
    orphans: 0;
    font-family: "Aptos Display";
    font-size: 20pt;
    font-weight: normal;
    color: #2e74b5;
  }
  h3 {
    margin-top: 8pt;
    margin-bottom: 2pt;
    page-break-inside: avoid;
    page-break-after: avoid;
    line-height: 116%;
    widows: 0;
    orphans: 0;
    font-family: Aptos;
    font-size: 16pt;
    font-weight: normal;
    color: #2e74b5;
  }
  h4 {
    margin-top: 8pt;
    margin-bottom: 2pt;
    page-break-inside: avoid;
    page-break-after: avoid;
    line-height: 116%;
    widows: 0;
    orphans: 0;
    font-family: Aptos;
    font-size: 14pt;
    font-weight: normal;
    font-style: normal;
    color: #2e74b5;
  }
  h5 {
    margin-top: 4pt;
    margin-bottom: 2pt;
    page-break-inside: avoid;
    page-break-after: avoid;
    line-height: 116%;
    widows: 0;
    orphans: 0;
    font-family: Aptos;
    font-size: 12pt;
    font-weight: normal;
    color: #2e74b5;
  }
  h6 {
    margin-top: 2pt;
    margin-bottom: 0pt;
    page-break-inside: avoid;
    page-break-after: avoid;
    line-height: 116%;
    widows: 0;
    orphans: 0;
    font-family: Aptos;
    font-size: 12pt;
    font-weight: normal;
    color: #595959;
  }
  .Heading7 {
    margin-top: 2pt;
    margin-left: 5pt;
    margin-bottom: 0pt;
    page-break-inside: avoid;
    page-break-after: avoid;
    line-height: 116%;
    widows: 0;
    orphans: 0;
    font-family: Aptos;
    font-size: 12pt;
    font-weight: normal;
    font-style: normal;
    color: #595959;
    -aw-style-name: heading7;
  }
  .Heading8 {
    margin-top: 2pt;
    margin-left: 10pt;
    margin-bottom: 0pt;
    page-break-inside: avoid;
    page-break-after: avoid;
    line-height: 116%;
    widows: 0;
    orphans: 0;
    font-family: Aptos;
    font-size: 12pt;
    font-weight: normal;
    color: #272727;
    -aw-style-name: heading8;
  }
  .Heading9 {
    margin-top: 2pt;
    margin-left: 15pt;
    margin-bottom: 0pt;
    page-break-inside: avoid;
    page-break-after: avoid;
    line-height: 116%;
    widows: 0;
    orphans: 0;
    font-family: Aptos;
    font-size: 12pt;
    font-weight: normal;
    font-style: normal;
    color: #272727;
    -aw-style-name: heading9;
  }
  .Footer {
    margin-bottom: 0pt;
    line-height: normal;
    widows: 0;
    orphans: 0;
    font-family: Calibri;
    font-size: 10pt;
    -aw-style-name: footer;
  }
  .Header {
    margin-bottom: 0pt;
    line-height: normal;
    widows: 0;
    orphans: 0;
    font-family: Calibri;
    font-size: 10pt;
    -aw-style-name: header;
  }
  .IntenseQuote {
    margin: 18pt 43.2pt;
    text-align: center;
    line-height: 116%;
    widows: 0;
    orphans: 0;
    border-top: 0.75pt solid #2e74b5;
    border-bottom: 0.75pt solid #2e74b5;
    padding-top: 10pt;
    padding-bottom: 10pt;
    font-family: Aptos;
    font-size: 12pt;
    font-style: italic;
    color: #2e74b5;
    -aw-border-bottom: 0.5pt single;
    -aw-border-top: 0.5pt single;
    -aw-style-name: intense-quote;
  }
  .ListParagraph {
    margin-left: 36pt;
    margin-bottom: 8pt;
    line-height: 116%;
    widows: 0;
    orphans: 0;
    font-family: Aptos;
    font-size: 12pt;
    -aw-style-name: list-paragraph;
  }
  .Quote {
    margin-top: 8pt;
    margin-bottom: 8pt;
    text-align: center;
    line-height: 116%;
    widows: 0;
    orphans: 0;
    font-family: Aptos;
    font-size: 12pt;
    font-style: italic;
    color: #404040;
    -aw-style-name: quote;
  }
  .Subtitle {
    margin-bottom: 8pt;
    text-align: center;
    line-height: 116%;
    widows: 0;
    orphans: 0;
    font-family: "Aptos Display";
    font-size: 14pt;
    letter-spacing: 0.75pt;
    color: #595959;
    -aw-style-name: subtitle;
  }
  .Title {
    margin-bottom: 4pt;
    text-align: center;
    line-height: normal;
    widows: 0;
    orphans: 0;
    font-family: "Aptos Display";
    font-size: 28pt;
    letter-spacing: -0.5pt;
    -aw-style-name: title;
  }
  span.IntenseEmphasis {
    font-style: italic;
    color: #2e74b5;
    -aw-style-name: intense-emphasis;
  }
  span.IntenseReference {
    font-weight: bold;
    font-variant: small-caps;
    letter-spacing: 0.25pt;
    color: #2e74b5;
    -aw-style-name: intense-reference;
  }
  span.a0 {
    font-family: "Aptos Display";
    font-size: 14pt;
    letter-spacing: 0.75pt;
    color: #595959;
  }
  span.a1 {
    font-style: italic;
    color: #404040;
  }
  span.\31 {
    font-family: "Aptos Display";
    font-size: 24pt;
    color: #2e74b5;
  }
  span.\32 {
    font-family: "Aptos Display";
    font-size: 20pt;
    color: #2e74b5;
  }
  span.\33 {
    font-size: 16pt;
    color: #2e74b5;
  }
  span.\34 {
    font-size: 14pt;
    color: #2e74b5;
  }
  span.\35 {
    color: #2e74b5;
  }
  span.\36 {
    color: #595959;
  }
  span.\37 {
    color: #595959;
  }
  span.\38 {
    color: #272727;
  }
  span.\39 {
    color: #272727;
  }
  span.a {
    font-family: "Aptos Display";
    font-size: 28pt;
    letter-spacing: -0.5pt;
  }
  span.a4 {
    font-family: Calibri;
    font-size: 10pt;
  }
  span.a3 {
    font-family: Calibri;
    font-size: 10pt;
  }
  span.a2 {
    font-style: italic;
    color: #2e74b5;
  }
  .TableGrid {
  }
  .section-1 {
    p {
      margin-top: 2px;
    }
  }
  .section-border {
    margin-left: -6px;
    width: calc(100% + 11px);
    border: 2px solid black;
    padding-left: 4px;
  }
}
</style>
